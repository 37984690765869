import React from 'react';
import { Grid } from 'semantic-ui-react';
import LightBox from '../components/lightbox'
import Banner from "../components/banner"
import SEO from '../components/seo';
import Layout from '../components/layout';

export default class Template extends React.Component {
	render() {
		const { pageContext, path, ...rest } = this.props;
		const { data } = pageContext;
		const { titleImage, housingForm, description, project, category, photos = [] } = data;
		// serviceType
		const hero = {
			fluid: titleImage.childImageSharp.fluid,
			...photos.find(o => o.id === titleImage.id),
		};
		return (
			<Layout path={path} pageContext={pageContext} {...rest}>
				<div className='page featured'>
					<SEO title={`Featured | ${project}`} />
					<Banner actionText="Contact us">Gallery - {project}</Banner>
					<section className="content titleimage">
						<div>
							<LightBox className="titleimage" showCaption {...hero.fluid} alt={hero.alt} caption={hero.caption} style={{ width: '100%' }}/>
						</div>
					</section>
					<section className="content description">
						<div>
							<h3>{`${housingForm} - ${category}`}</h3>
							<p>{`${description}`}</p>
						</div>
					</section>

					<section className="content gallery">
						<div>
							<Grid className="gallery" columns={3}>
								{photos.map(({ filename, caption, alt }) => {
									// const { src, srcSet } = filename.childImageSharp.fluid;
									return (
										<Grid.Column key={filename.id}>
											<LightBox showCaption {...filename.childImageSharp.fluid} alt={alt} caption={caption} />
										</Grid.Column>
									);
								})}
							</Grid>
						</div>
					</section>
				</div>
			</Layout>
		);
	}
}
